<template>
  <component :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'MemberAddManager',

  components: {
    BuyerMemberAdd: () => import('./BuyerMemberAdd'),
    VendorMemberAdd: () => import('./VendorMemberAdd'),
    SuperMemberAdd: () => import('./SuperMemberAdd'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperMemberAdd';
        case PersonaConstants.vendor.value:
          return 'VendorMemberAdd';
        case PersonaConstants.buyer.value:
        default:
          return 'BuyerMemberAdd';
      }
    },
  },
};
</script>
